import createTsdApi from "../../../client-api/createTsdApi";
import {
  InvoicePaymentReminder,
  InvoicePaymentReminderCreateParams,
  InvoicePaymentReminderQueryParams,
  InvoicePaymentReminderUpdateParams,
} from "../../../entities/InvoicePaymentReminder/invoicePaymentReminder";
import { TsdSearchParam } from "../fetch";

const invoicePaymentReminderApi = createTsdApi<
  InvoicePaymentReminder,
  InvoicePaymentReminderQueryParams,
  InvoicePaymentReminderCreateParams,
  InvoicePaymentReminderUpdateParams
>("invoice-payment-reminders", (params) => {
  const search: TsdSearchParam<InvoicePaymentReminderQueryParams>[] = [];

  ({
    ids: (() => {
      if (typeof params.ids !== "undefined") {
        params.ids.forEach((id) =>
          search.push({ key: "ids", value: String(id) })
        );
      }
    })(),
    customerIds: (() => {
      if (typeof params.customerIds !== "undefined") {
        params.customerIds.forEach((id) =>
          search.push({ key: "customerIds", value: String(id) })
        );
      }
    })(),
    contactIds: (() => {
      if (typeof params.contactIds !== "undefined") {
        params.contactIds.forEach((id) =>
          search.push({ key: "contactIds", value: String(id) })
        );
      }
    })(),
    sentDatetimeGte: (() => {
      if (typeof params.sentDatetimeGte !== "undefined") {
        search.push({ key: "sentDatetimeGte", value: params.sentDatetimeGte });
      }
    })(),
    sentDatetimeLte: (() => {
      if (typeof params.sentDatetimeLte !== "undefined") {
        search.push({ key: "sentDatetimeLte", value: params.sentDatetimeLte });
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<InvoicePaymentReminderQueryParams>]:
      | void
      | undefined;
  };

  return search;
});

export default {
  get: invoicePaymentReminderApi.get,
  getOne: invoicePaymentReminderApi.getOne,
  create: invoicePaymentReminderApi.create,
};
